import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import FlipCountdown from '../components/Flipdown';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import config from '../../config';

const IndexPage = (props) => {
  const countdown = () => {
    const countDate = new Date('May 27, 2023 14:00:00').getTime();
    const now = new Date().getTime();
    const gap = countDate - now;

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const textDay = Math.floor(gap / day);
    const textHour = Math.floor((gap % day) / hour);
    const textMinute = Math.floor((gap % hour) / minute);
    const textSecond = Math.floor((gap % minute) / second);

    return {
      textDay: textDay > 0 ? textDay : 0,
      textHour: textHour > 0 ? textHour : 0,
      textMinute: textMinute > 0 ? textMinute : 0,
      textSecond: textSecond > 0 ? textSecond : 0,
    };
  };

  const [countdownText, setCountdownText] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      const { textDay, textHour, textMinute, textSecond } = countdown();
      setCountdownText({ textDay, textHour, textMinute, textSecond });
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <Layout fullMenu location={props.location}>
      <section id="banner">
        <div className="inner color-white-title">
          {/* <h2>{config.heading}</h2> */}
          <h2 className="color-white-title">Save the Date</h2>
          <p>May 27, 2023</p>
          {/* <p>{config.subHeading}</p> */}
          <ul className="actions special">
            <li>
              {/* <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll> */}
            </li>
          </ul>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more">
            More Info
          </a>
        </Scroll>
      </section>

      <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <h2 className="section-title">Wedding Ceremony</h2>
            <p>
              Vietnamese Martyrs Catholic Church
              <br />
              Austin, Texas
            </p>
          </header>
          <ul className="icons major">
            <li>
              <span className="image fit eventimage">
                <StaticImage
                  src="../assets/images/JL.png"
                  alt="JL"
                  placeholder="blurred"
                  width={"180px"}
                />
              </span>
            </li>
          </ul>
          <header className="major">
            <h2 className="section-title">Reception</h2>
            <p>
              Celebrino Event Center
              <br />
              Georgetown, Texas
            </p>
          </header>
              {/* <Link
                to="/rsvp"
                className="button primary wedding-button"
              >
                RSVP Now
              </Link> */}
        </div>
      </section>

      <section id="cta" className="wrapper style3">
        <div className="inner">
          <header></header>
        </div>
      </section>

      {/* <section id="three" className="wrapper special">
        <div className="inner">
          <header className="major">
            <h2 className='section-title'>Happily Ever After In...</h2>
            <FlipCountdown
              hideYear
              endAt={'2023-05-27 00:00:00'} // Date/Time
              titlePosition='bottom'
              theme="light"
              size='medium'
              monthTitle='Months'
              dayTitle='Days'
              hourTitle='Hours'
              minuteTitle='Minutes'
              secondTitle='Seconds'
              />
          </header>
        </div>
      </section> */}

      <section id="cta" className="wrapper style3">
        <div className="inner">
          <header></header>
        </div>
      </section>

      {/* <section id="two" className="wrapper alt style2">
        <section className="spotlight">
          <div className="image">
            <StaticImage
              src="../assets/images/EngagementPhotos/bridge-looking.jpg"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="content">
            <h2>
              Magna primis lobortis
              <br />
              sed ullamcorper
            </h2>
            <p>
              Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
              imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <StaticImage
              src="../assets/images/EngagementPhotos/shoulder.jpg"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="content">
            <h2>
              Tortor dolore feugiat
              <br />
              elementum magna
            </h2>
            <p>
              Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
              imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <StaticImage
              src="../assets/images/EngagementPhotos/ringfocus.jpg"
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="content">
            <h2>
              Augue eleifend aliquet
              <br />
              sed condimentum
            </h2>
            <p>
              Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
              imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
            </p>
          </div>
        </section>
      </section> */}

      
    </Layout>
  );
};

export default IndexPage;
